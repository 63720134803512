import { ReactNode } from 'react';
import classNames from 'classnames';

export default function ListItem(props: {
  children: ReactNode;
  sideContent?: ReactNode;
  sideContentAlign?: 'center' | 'top' | 'bottom';
  className?: string;
}): JSX.Element {
  return (
    <div className={classNames('flex justify-between gap-2 border-b bg-white p-4', props.className)}>
      <div className="grow">
        <div>{props.children}</div>
      </div>

      <div
        className={classNames('flex flex-col', {
          'justify-center': props.sideContentAlign == 'center',
          'justify-start': props.sideContentAlign == 'top',
          'justify-end': props.sideContentAlign == 'bottom',
        })}
      >
        {props.sideContent}
      </div>
    </div>
  );
}
