import AppointmentServiceStart from '@app/page/appointment/appointment-create/component/appointment-service-start.tsx';
import AppointmentServiceAndTime from '@app/page/appointment/appointment-create/component/appointment-service-and-time.tsx';
import AppointmentServiceSelection from '@app/page/appointment/appointment-create/component/appointment-service-selection.tsx';
import AppointmentServiceContactInfo from '@app/page/appointment/appointment-create/component/appointment-service-contact-info.tsx';
import { useAppointmentBranchConfiguration } from '@app/page/appointment/appointment-create/logic/use-get-appointment-branch-configuration.ts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getFragmentData } from '@app/graphql/types';
import { ServiceFragment, ServiceProductFragment } from '@app/page/online-order/model/appointment-fragment.ts';
import { BranchFragment } from '@app/page/online-order/model/branch-fragment.ts';
import { useCreateAppointment } from '@app/page/appointment/appointment-create/logic/use-create-appointment.ts';
import {
  AppointmentMonitor,
  useAppointmentInfo,
} from '@app/page/appointment/appointment-create/logic/use-appointment-info.ts';
import { useEffect } from 'react';
import { useTranslate } from '@tolgee/react';
import Loading from '@app/components/loading.tsx';
import { LanguageSelector } from '@app/page/table-reservation/component/table-reservation-create-form.tsx';
import HolidayTimeOutBanner from '@app/page/appointment/appointment-create/component/holiday-time-out-banner.tsx';

export default function AppointmentCreate() {
  const [searchParams] = useSearchParams();
  const branchId = searchParams.get('branchID') ?? '';

  const navigate = useNavigate();
  const { t } = useTranslate();

  const state = useAppointmentInfo((state) => state);
  const { currentMonitor, appointmentInput, pickedProduct, setBranch, reset } = state;

  const { data, loading } = useAppointmentBranchConfiguration(branchId);
  const [create] = useCreateAppointment();

  const menu = getFragmentData(ServiceFragment, data?.storefront_appointmentBranchConfiguration.menu);
  const branch = getFragmentData(BranchFragment, data?.storefront_appointmentBranchConfiguration.branch);

  useEffect(() => {
    if (!branch) {
      return;
    }
    setBranch(branch);
  }, [branch, setBranch]);

  if (loading) {
    return <Loading />;
  }

  if (!branch) {
    return (
      <div className="grid min-h-screen place-items-center bg-gray-100 px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {t('error.no-branch.title', 'Branch Not Found')}
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            {t('error.no-branch.message', 'Sorry, we couldn’t find the selected branch.')}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="#"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {t('error.cta.back-home', 'Go back home')}
            </a>
            <a href="https://www.ninetysixdigital.com/" className="text-sm font-semibold text-gray-900">
              {t('error.cta.contact-support', 'Contact support')} <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </div>
    );
  }

  if (!menu) {
    return <>Menu not found</>;
  }

  const selectedProduct = getFragmentData(
    ServiceProductFragment,
    menu.menuProducts.find((productFragment) => {
      const product = getFragmentData(ServiceProductFragment, productFragment);
      return product.id == pickedProduct?.productID;
    })
  );

  const handleSubmit = () => {
    if (!pickedProduct) {
      alert(t('storefront-appointment.create.error.no-picked-product', 'Please select a product'));
      return;
    }

    create({
      variables: {
        branchID: branchId,
        input: {
          ...appointmentInput,
          customerLanguage: localStorage.getItem('language'),
        },
        pickedProductInput: [
          {
            ...pickedProduct,
            memberID: state.memberId,
          },
        ],
      },
    })
      .then((result) => {
        reset();
        navigate(`/appointment/track/${result.data?.storefront_createAppointment.id ?? ''}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <HolidayTimeOutBanner branchId={branchId} timezone={branch.company.settings.timezone} />
      {currentMonitor == AppointmentMonitor.start && <AppointmentServiceStart menu={menu} branch={branch} />}
      {currentMonitor == AppointmentMonitor.time && <AppointmentServiceAndTime selectedProduct={selectedProduct} />}
      {currentMonitor == AppointmentMonitor.selection && <AppointmentServiceSelection />}
      {currentMonitor == AppointmentMonitor.contactInfo && (
        <AppointmentServiceContactInfo selectedProduct={selectedProduct} handleSubmit={handleSubmit} />
      )}
      <div className="hidden">
        <LanguageSelector customerDefaultLanguage={branch.company.settings.customerDefaultLanguage} />
      </div>
    </>
  );
}
