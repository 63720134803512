import { ReactNode, useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import classNames from 'classnames';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import CardTableContent from '@app/components/card/card-content-table.tsx';

const LeftModalButton = (props: { title: string; content?: ReactNode; children?: ReactNode }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.children}
      </div>

      <Dialog className="relative z-10" open={open} onClose={setOpen}>
        <DialogBackdrop transition={true} className="fixed inset-0 bg-black/30" />

        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-y-scroll">
          <div className="absolute inset-0 overflow-y-scroll">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full max-sm:pt-10">
              <DialogPanel
                transition
                className={classNames(
                  'pointer-events-auto relative w-screen max-w-md transform transition duration-500',
                  'ease-in-out max-sm:data-[closed]:translate-y-full sm:duration-700 md:data-[closed]:-translate-x-full'
                )}
              >
                <Card className="absolute inset-x-0 bottom-0 flex h-3/4 flex-col overflow-y-scroll">
                  <div className="sticky top-0 border-b bg-white">
                    <CardHeader
                      title={props.title}
                      onCrossButton={() => {
                        setOpen(false);
                      }}
                    />
                  </div>
                  <CardTableContent className={'grow'}>{props.content}</CardTableContent>
                </Card>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default LeftModalButton;
