import { useParams } from 'react-router-dom';
import { useOnlineOrderDetailPageQuery } from '@app/page/online-order/detail/logic/use-online-order-detail-page-query.ts';
import { getFragmentData } from '@app/graphql/types';
import { OnlineOrderFragment } from '@app/page/online-order/model/online-order-fragment.ts';
import Loading from '@app/components/loading.tsx';
import ApplicationErrorView from '@app/module/error/application-error-view.tsx';
import { OnlineOrderUserState } from '@app/graphql/types/graphql.ts';
import { ReactNode, useEffect } from 'react';
import { CurrencyContext } from '@app/components/price/use-currency.ts';
import { BranchFragment } from '@app/page/online-order/model/branch-fragment.ts';
import useOnlineOrderUpdateSubscription from '@app/page/online-order/detail/logic/use-online-order-update-subscription.ts';
import { captureException } from '@sentry/react';
import { useOnlineOrderDetailStore } from '@app/page/online-order/detail/logic/use-online-order-detail-store.ts';
import { OnlineOrderBranchConfigurationFragment } from '@app/page/online-order/model/online-order-branch-configuration.ts';
import PageNotFound from '../product-picker/component/page-not-found.tsx';
import FillContactInfoPage from '@app/page/online-order/fill-contact-info/fill-contact-info-page.tsx';
import OnlineOrderReviewPage from '@app/page/online-order/review/online-order-review-page.tsx';
import OrderDetailPage from '@app/page/online-order/order-detail-page/order-detail-page.tsx';
import ProductRepickerPage from '@app/page/online-order/product-picker/product-repicker-page.tsx';

const OnlineOrderDetailPage = () => {
  const params = useParams();

  const { onlineOrderID } = params;

  const { data, loading, error, refetch, previousData } = useOnlineOrderDetailPageQuery(onlineOrderID);
  const { data: updateStreamData } = useOnlineOrderUpdateSubscription(onlineOrderID);

  const lastUpdateTimestamp = useOnlineOrderDetailStore((state) => state.lastUpdateTimestamp);

  const onlineOrder = getFragmentData(OnlineOrderFragment, data?.storefront_onlineOrder);
  const prevOnlineOrder = getFragmentData(OnlineOrderFragment, previousData?.storefront_onlineOrder);
  const branch = getFragmentData(BranchFragment, onlineOrder?.branch);
  const configuration = getFragmentData(
    OnlineOrderBranchConfigurationFragment,
    data?.storefront_onlineOrderBranchConfigurationByOrderID
  );

  useEffect(() => {
    refetch().catch(captureException);
  }, [refetch, updateStreamData, lastUpdateTimestamp]);

  useEffect(() => {
    if (prevOnlineOrder?.userState !== onlineOrder?.userState) {
      window.scrollTo(0, 0);
    }
  }, [onlineOrder?.userState, prevOnlineOrder?.userState]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ApplicationErrorView error={error} />;
  }

  if (!onlineOrder || !branch || !configuration) {
    return <PageNotFound />;
  }

  let page: ReactNode;

  switch (onlineOrder.userState) {
    case OnlineOrderUserState.PickingProduct:
      page = <ProductRepickerPage order={onlineOrder} />;
      break;

    case OnlineOrderUserState.FillContactInfo:
      page = <FillContactInfoPage branch={branch} order={onlineOrder} onlineOrderBranchConfiguration={configuration} />;
      break;

    case OnlineOrderUserState.Review:
      page = <OnlineOrderReviewPage onlineOrder={onlineOrder} branch={branch} />;
      break;

    case OnlineOrderUserState.Done:
      page = <OrderDetailPage onlineOrder={onlineOrder} branch={branch} />;
      break;

    default:
      page = <PageNotFound />;
      break;
  }

  return <CurrencyContext.Provider value={branch.company.settings.currency}>{page}</CurrencyContext.Provider>;
};

export default OnlineOrderDetailPage;
