import { ReactNode } from 'react';
import { ButtonSize } from '@app/components/button-size.ts';
import classNames from 'classnames';
import Spinner from '@app/components/spinner.tsx';
import { SpinnerSize } from '@app/components/spinner-size.ts';

export default function SuperButton(props: {
  id?: string;
  children?: ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  fixed?: boolean;
  buttonSize?: ButtonSize;
  loading?: boolean;
  'data-testid'?: string;
}) {
  return (
    <button
      id={props.id}
      data-testid={props['data-testid']}
      type="button"
      disabled={props.disabled ?? false}
      onClick={props.onClick}
      className={classNames(
        'w-full rounded-2xl bg-black py-2 font-semibold text-white shadow-sm',
        'disabled:bg-gray-400',
        'hover:bg-gray-500',
        'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600',
        props.fixed && 'bottom-1.5 rounded-lg max-sm:fixed',
        props.className
      )}
    >
      <div className="flex justify-center">
        {props.loading && (
          <div className="mr-2 h-4 w-4">
            <Spinner size={SpinnerSize.Small} />
          </div>
        )}
        {props.children}
      </div>
    </button>
  );
}
