import { MenuItemFragment } from '@app/graphql/types/graphql.ts';
import { useTranslate } from '@tolgee/react';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import { calculateTotal } from '@app/page/online-order/product-picker/logic/calculate-total.tsx';
import { pickedProductID, useProductPicker } from '@app/page/online-order/product-picker/logic/use-product-picker.ts';
import CurrencyView from '@app/components/price/currency-view.tsx';
import CardTableContent from '@app/components/card/card-content-table.tsx';
import ListItem from '@app/components/list/list-item.tsx';
import { getFragmentData } from '@app/graphql/types';
import {
  MenuProductConfigurationFragment,
  MenuProductConfigurationValueFragment,
  MenuProductFragment,
} from '@app/page/online-order/model/menu-product-fragment.ts';
import { calculatePickedProduct } from '@app/page/online-order/product-picker/logic/calculate-picked-product.ts';
import CartButton from '@app/page/online-order/product-picker/component/cart-button.tsx';

export default function CardSideBar(props: {
  menu: MenuItemFragment;
  onCreate?: () => void;
  disableOrderButton?: boolean;
}) {
  const { t } = useTranslate();
  const pickedProducts = useProductPicker((state) => state.products);
  const products = getFragmentData(MenuProductFragment, props.menu.menuProducts);

  const total = calculateTotal(pickedProducts, props.menu);

  return (
    <Card>
      <CardHeader title={t('online-order.cart-bar.header', 'Cart')} />
      <CardTableContent>
        {pickedProducts.map((pickedProduct) => {
          const product = products.find((product) => product.id === pickedProduct.productID);

          if (!product) {
            return <div key={pickedProductID(pickedProduct)}>Unknown product {pickedProduct.productID}</div>;
          } else {
            const configurations = getFragmentData(MenuProductConfigurationFragment, product.configurations);

            const totalProductPrice = calculatePickedProduct(
              pickedProduct.configurations.map((e) => e.valueID),
              product,
              pickedProduct.quantity
            );

            const configurationValueText = pickedProduct.configurations
              .map((pickedConfig) => {
                const configuration = configurations.find((c) => c.id === pickedConfig.configurationID);

                if (!configuration) {
                  return `Unknown configuration ${pickedConfig.configurationID}`;
                } else {
                  const values = getFragmentData(MenuProductConfigurationValueFragment, configuration.values);
                  const pickedValue = values.find((v) => v.id === pickedConfig.valueID);

                  if (!pickedValue) {
                    return `Unknown value ${pickedConfig.valueID}`;
                  } else {
                    return pickedValue.name;
                  }
                }
              })
              .join(', ');

            return (
              <ListItem
                key={pickedProductID(pickedProduct)}
                sideContent={
                  <div className="flex-shrink-0">
                    <div className="flex flex-col items-end text-sm">
                      <CurrencyView price={totalProductPrice} />
                    </div>
                  </div>
                }
                sideContentAlign={'top'}
              >
                <div className="item-start flex gap-4">
                  <div className="flex items-center gap-1">
                    <CartButton pickedProduct={pickedProduct} />
                  </div>

                  <div className="flex flex-col justify-between">
                    {/*Title*/}
                    <div className="line-clamp-2 font-medium">
                      {product.code}. {product.title}
                    </div>
                    {/*Description*/}
                    <div className="line-clamp-2 flex flex-wrap space-x-1 text-sm text-gray-400">
                      {configurationValueText}
                    </div>

                    <div className="text-gtay-700 text-sm underline">Edit</div>
                  </div>
                </div>
              </ListItem>
            );
          }
        })}
        <ListItem className="px-2 py-2">
          <div
            className="text-light flex w-full justify-between rounded-3xl bg-gray-900 px-4 py-2 text-sm text-white"
            onClick={props.onCreate}
          >
            <div className="flex items-center gap-1">
              <span>{t('online-order.cart-bar.button.checkout', 'Check out')}</span>
              <span className="full scale-75 rounded border border-4 font-bold"></span>
              <span>
                {pickedProducts.reduce((total, item) => total + item.quantity, 0)}
                {t('online-order.cart-bar.button.item', ' Items')}
              </span>
            </div>
            <span>
              <CurrencyView className="ml-1" price={total} />
            </span>
          </div>
        </ListItem>
      </CardTableContent>
    </Card>
  );
}
