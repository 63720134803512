import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import globalRouter from './global-router.tsx';
import { ApolloProvider } from '@apollo/client';
import client from './utils/client.ts';
import { pdfjs } from 'react-pdf';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration, replayIntegration } from '@sentry/react';
import './utils/analytics.ts';
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react';
import { TreeTranslationsData } from '@tolgee/core';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const sentryDns: string | null = import.meta.env.VITE_SENTRY_DNS as string;
if (sentryDns) {
  Sentry.init({
    dsn: sentryDns,
    integrations: [browserTracingIntegration(), replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: import.meta.env.PROD ? 1.0 : 0.2,
    // Session Replay
    replaysSessionSampleRate: import.meta.env.PROD ? 0.1 : 1,
    replaysOnErrorSampleRate: 1.0,
  });

  console.log('Sentry initialized');
}

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .init({
    language: localStorage.getItem('language') ?? 'de',
    // for development
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY,

    // for production
    staticData: {
      en: () => import('./i18n/en.json').then((m) => m.default as TreeTranslationsData),
      de: () => import('./i18n/de.json').then((m) => m.default as TreeTranslationsData),
      vi: () => import('./i18n/vi.json').then((m) => m.default as TreeTranslationsData),
    },
  });

const rootElement = document.getElementById('root');
if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <TolgeeProvider tolgee={tolgee}>
        <ApolloProvider client={client}>
          <RouterProvider router={globalRouter} />
        </ApolloProvider>
      </TolgeeProvider>
    </React.StrictMode>
  );
} else {
  console.error('Element with ID "root" not found');
}
