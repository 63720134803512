import { ChangeEvent } from 'react';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import CardContent from '@app/components/card/card-content.tsx';
import FormLayout from '@app/components/form/form-layout.tsx';
import TextInput from '@app/components/form/input/text-input.tsx';
import FormItem from '@app/components/form/form-item.tsx';
import PhoneInput from 'react-phone-number-input/min';
import '../phone-style.css';
import DualItem from '@app/components/form/dual-form-item.tsx';

export default function ContactInputCard(props: {
  values: {
    guestName: string;
    email: string;
    phone: string;
    message: string;
  };
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  manualHandle: (name: string, value: unknown) => void;
  errors?: Record<string, string>;
}) {
  const { values, onChange, errors, manualHandle } = props;
  const { t } = useTranslate();
  return (
    <Card>
      <CardHeader title={t('online-order.fill-contact-page.form-input.delivery.contact-data.title', 'Contact data')} />

      <CardContent>
        <FormLayout>
          <FormItem
            title={t('online-order.fill-contact-page.form-input.delivery.contact-data.name.label', 'Your name')}
            className="mp-2 max-w-md"
          >
            <TextInput
              type="text"
              label="guestName"
              value={values.guestName}
              name="guestName"
              placeholder={t(
                'online-order.fill-contact-page.form-input.delivery.contact-data.name.placeholder',
                'Name'
              )}
              onChange={(e) => {
                onChange(e as ChangeEvent<HTMLInputElement>);
              }}
              error={errors?.guestName}
            />
          </FormItem>

          <DualItem>
            <FormItem
              title={t(
                'online-order.fill-contact-page.form-input.delivery.contact-data.email.label',
                'Your email address'
              )}
              className="max-w-md"
            >
              <TextInput
                type="text"
                label="email"
                value={values.email}
                name="email"
                placeholder={t(
                  'online-order.fill-contact-page.form-input.delivery.contact-data.email.placeholder',
                  'Email'
                )}
                onChange={(e) => {
                  onChange(e as ChangeEvent<HTMLInputElement>);
                }}
                error={errors?.email}
              />
            </FormItem>

            {/*Phone*/}
            <FormItem
              title={t(
                'online-order.fill-contact-page.form-input.delivery.contact-data.phone.label',
                'Your phone number'
              )}
              className="max-w-md"
            >
              <PhoneInput
                className="block w-full overflow-hidden rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultCountry={'DE'}
                placeholder={t(
                  'online-order.fill-contact-page.form-input.delivery.contact-data.phone.placeholder',
                  'Enter phone number'
                )}
                name="phone"
                value={values.phone}
                onChange={(value) => {
                  manualHandle('phone', value?.toString() ?? '');
                }}
              />
              {errors?.phone && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {errors.phone}
                </p>
              )}
            </FormItem>
          </DualItem>

          {/*Message*/}
          <FormItem
            title={t('online-order.fill-contact-page.form-input.delivery.contact-data.message.label', 'Message')}
          >
            <TextInput
              name="message"
              label="message"
              onChange={(newVal) => {
                manualHandle('message', newVal.target.value);
              }}
              placeholder={t(
                'online-order.fill-contact-page.form-input.delivery.contact-data.message.placeholder',
                'Your additional message to restaurant'
              )}
              value={values.message}
              type={'textarea'}
              error={errors?.message}
            />
          </FormItem>
        </FormLayout>
      </CardContent>
    </Card>
  );
}
