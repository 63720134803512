import { MenuProductItemFragment, OnlineOrderItemFragment, OnlineOrderUserState } from '@app/graphql/types/graphql.ts';
import { useLocalStorage } from 'usehooks-ts';
import { getFragmentData } from '@app/graphql/types';
import { BranchFragment } from '@app/page/online-order/model/branch-fragment.ts';
import { useProductPickerQuery } from '@app/page/online-order/product-picker/logic/use-product-picker-page-query.ts';
import { useOnlineOrderSetPickedProducts } from '@app/page/online-order/product-picker/logic/use-online-order-set-picked-products.ts';
import { useProductPicker } from '@app/page/online-order/product-picker/logic/use-product-picker.ts';
import { OnlineOrderBranchConfigurationFragment } from '@app/page/online-order/model/online-order-branch-configuration.ts';
import { MenuFragment, MenuProductFragment } from '@app/page/online-order/model/menu-product-fragment.ts';
import { MenuSectionFragment } from '@app/page/online-order/model/menu-section-fragment.ts';
import { useEffect, useState } from 'react';
import { OnlineOrderProductsFragment } from '@app/page/online-order/model/online-order-fragment.ts';
import Loading from '@app/components/loading.tsx';
import CriticalError from '@app/page/online-order/product-picker/component/critical-error.tsx';
import UnavailableOnlineOrder from '@app/page/online-order/product-picker/component/unavailable-online-order.tsx';
import { captureException } from '@sentry/react';
import ApplicationErrorView from '@app/module/error/application-error-view.tsx';
import BusinessCard from '@app/page/online-order/product-picker/component/business-card.tsx';
import ProductPickerList from '@app/page/online-order/product-picker/component/product-picker-list.tsx';
import CartBar from '@app/page/online-order/product-picker/component/cart-bar.tsx';
import CardSideBar from '@app/page/online-order/product-picker/component/cart-side-bar.tsx';
import FunctionalBar from '@app/page/online-order/product-picker/component/functional-bar.tsx';

export default function ProductRepickerPage(props: { order: OnlineOrderItemFragment }) {
  const [customerLanguage] = useLocalStorage('language', 'de');
  const branch = getFragmentData(BranchFragment, props.order.branch);

  const { data, loading, error } = useProductPickerQuery(branch.id);
  const [setPickedProducts, { loading: mutatingProducts }] = useOnlineOrderSetPickedProducts();
  const [products, setProducts] = useState<readonly MenuProductItemFragment[]>([]);

  const mutating = mutatingProducts;

  const pickedProducts = useProductPicker((state) => state.products);

  const continueCart = useProductPicker((state) => state.continue);
  const clearCart = useProductPicker((state) => state.clear);

  const configuration = getFragmentData(
    OnlineOrderBranchConfigurationFragment,
    data?.storefront_onlineOrderBranchConfiguration
  );

  const menu = getFragmentData(MenuFragment, configuration?.menu);
  const sections = getFragmentData(MenuSectionFragment, menu?.sections) ?? [];

  useEffect(() => {
    if (!data) {
      return;
    }

    const products = getFragmentData(MenuProductFragment, menu?.menuProducts) ?? [];
    setProducts(products);
  }, [data, menu?.menuProducts]);

  useEffect(() => {
    const pickedProducts = getFragmentData(OnlineOrderProductsFragment, props.order.onlineOrderProducts);
    continueCart(pickedProducts);
  }, [continueCart, props.order.onlineOrderProducts]);

  if (loading) return <Loading />;

  if (error) {
    return <CriticalError />;
  }

  if (!data?.storefront_onlineOrderBranchConfiguration) {
    return <UnavailableOnlineOrder website={branch.website ?? ''} />;
  }

  if (!menu) {
    return <UnavailableOnlineOrder website={branch.website ?? ''} />;
  }

  const handleUpdate = () => {
    setPickedProducts({
      variables: {
        customerLanguage: customerLanguage,
        onlineOrderID: props.order.id,
        products: pickedProducts.map((product) => ({
          productID: product.productID,
          quantity: product.quantity,
          configurations: product.configurations.map((config) => ({
            configurationID: config.configurationID,
            valueID: config.valueID,
          })),
        })),
        state: OnlineOrderUserState.FillContactInfo,
      },
    })
      .then(() => {
        clearCart();
      })
      .catch(captureException);
  };

  const searchProduct = (searchString: string) => {
    const products = getFragmentData(MenuProductFragment, menu.menuProducts);

    const newProducts = products.filter((item) => {
      if (item.title.toLowerCase().includes(searchString.toLowerCase().trim())) {
        return true;
      }
      return false;
    });

    setProducts(newProducts);
  };

  return (
    <div>
      <div className="flex flex-col bg-gray-100 sm:p-4">
        <ApplicationErrorView className="my-4" error={error} />

        <div className="flex flex-col items-start justify-center gap-4 sm:flex-row">
          <div className="w-full space-y-4 xl:basis-1/2">
            <BusinessCard branch={branch} />

            <div>
              <div className="sticky top-0">
                <FunctionalBar searchProduct={searchProduct} sections={sections} products={products} />
              </div>
              <ProductPickerList timezone={branch.company.settings.timezone} sections={sections} products={products} />
            </div>
          </div>

          <div className="no-scrollbar top-4 hidden max-h-screen overflow-y-scroll xl:sticky xl:block xl:basis-[28%] 2xl:basis-1/5">
            <CardSideBar menu={menu} onCreate={handleUpdate} disableOrderButton={mutatingProducts} />
          </div>

          <CartBar menu={menu} disableOrderButton={mutating} onCreate={handleUpdate} />
        </div>
      </div>
    </div>
  );
}
