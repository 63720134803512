import { formatCurrency } from '@app/components/price/currency-formatter.ts';
import { DeliveryDiscountType, PickupDiscountType } from '@app/graphql/types/graphql.ts';

export function pickupDiscountAdditionalText(
  pickupDiscountAmount: number,
  type: PickupDiscountType,
  currency: string
): string {
  switch (type) {
    case PickupDiscountType.None:
      return 'No pickup discount';
    case PickupDiscountType.Percentage:
      return `Discount for pickup online order: ${pickupDiscountAmount.toString()}` + '%';
    case PickupDiscountType.Amount:
      return `Discount for pickup online order: ${formatCurrency(pickupDiscountAmount, currency)} `;
  }
}

export function deliveryDiscountAdditionalText(
  deliveryDiscountAmount: number,
  type: DeliveryDiscountType,
  currency: string
): string {
  switch (type) {
    case DeliveryDiscountType.None:
      return 'No delivery discount';
    case DeliveryDiscountType.Percentage:
      return `Discount for delivery online order: ${deliveryDiscountAmount.toString()}` + '%';
    case DeliveryDiscountType.Amount:
      return `Discount for delivery online order: ${formatCurrency(deliveryDiscountAmount, currency)} `;
  }
}
