import SecondaryButton from '@app/components/secondary-button.tsx';
import classNames from 'classnames';

export default function DescriptionItem(props: {
  label: string;
  value: string;
  className?: string;
  href?: string;
  action?: string;
}) {
  return (
    <div
      className={classNames('flex flex-col items-start justify-between md:flex-row md:items-center', props.className)}
    >
      <div className="flex-1">
        <p className="text-sm text-gray-500">{props.label}</p>
        <a href={props.href} className="ml-0.5 line-clamp-2 text-ellipsis break-all text-lg md:max-w-md">
          {props.value}
        </a>
      </div>
      {props.action && (
        <div className="mt-2 md:mt-0 md:w-auto">
          <a href={props.href}>
            <SecondaryButton>{props.action}</SecondaryButton>
          </a>
        </div>
      )}
    </div>
  );
}
