import { Radio, RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useEffect } from 'react';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import CardTableContent from '@app/components/card/card-content-table.tsx';
import ListItem from '@app/components/list/list-item.tsx';

export interface CardSelectionData<T> {
  id: string;

  value: T;

  title: string;
  description: string;
  additionalText: string[];
}

export function CardSelection<T>(props: {
  title?: string;
  data: CardSelectionData<T>[];
  value: CardSelectionData<T>;
  onChange: (value: CardSelectionData<T>) => void;
}) {
  const { data, value, onChange } = props;

  useEffect(() => {
    if (data.length == 1) {
      onChange(data[0]);
    }
  });

  return (
    <Card>
      <CardHeader title={props.title ?? ''} />

      <CardTableContent>
        <RadioGroup value={value} onChange={onChange} className="">
          {data.map((item) => (
            <>
              <Radio key={item.id} value={item} aria-label={item.title} aria-description={item.description}>
                {({ checked }) => (
                  <ListItem
                    sideContent={item.additionalText.map((text, index) => (
                      <span key={index} className="hidden text-sm font-medium text-gray-900 sm:block">
                        {text}
                      </span>
                    ))}
                    sideContentAlign={'center'}
                    className="flex flex-1 hover:bg-gray-50"
                  >
                    <div className="flex items-center gap-4">
                      <div className="flex items-center">
                        <CheckCircleIcon
                          className={classNames(!checked ? 'hidden' : '', 'h-5 w-5 text-gray-700')}
                          aria-hidden="true"
                        />

                        <div
                          className={classNames(
                            !checked ? '' : 'hidden',
                            'h-4 w-4 rounded-full border border-2 border-gray-800 hover:border-gray-400'
                          )}
                        ></div>
                      </div>

                      <div className="flex flex-col">
                        <p className="block text-sm font-medium text-gray-900">{item.title}</p>
                        <p className="flex max-w-72 items-center text-sm text-gray-500">{item.description}</p>

                        <div className="mt-4 flex flex-col sm:hidden">
                          {item.additionalText.map((text, index) => (
                            <span key={index} className="text-sm font-medium text-gray-900">
                              {text}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </ListItem>
                )}
              </Radio>
            </>
          ))}
        </RadioGroup>
      </CardTableContent>
    </Card>
  );
}
