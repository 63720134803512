import { useNavigate } from 'react-router-dom';
import { PlatformType } from '@app/graphql/types/graphql.ts';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { formatGraphQlError, validationErrors } from '@app/module/error/error.ts';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { logEvent } from 'firebase/analytics';
import { analytics } from '@app/utils/analytics.ts';
import useCreateTableBooking from '../logic/use-create-table-booking.ts';
import useFormData from '@app/utils/use-form-data.ts';
import Spinner from '@app/components/spinner.tsx';
import { SpinnerSize } from '@app/components/spinner-size.ts';
import DateInput from './date-input.tsx';
import DurationInput from '@app/page/table-reservation/component/duration-input.tsx';
import { captureException } from '@sentry/react';
import { useTolgee, useTranslate } from '@tolgee/react';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useLocalStorage } from 'usehooks-ts';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import HolidayTimeOutBanner from '@app/page/appointment/appointment-create/component/holiday-time-out-banner.tsx';
import { useCompany } from '@app/page/menu-preview/use-menu-query.ts';

interface TableBookingCreateForm {
  branchId: string;
  platformType: PlatformType;
  seats: number;
  guestName: string;
  message: string;
  startTime: string;
  phone: string;
  email: string;
}

interface SelectedBranch {
  __typename?: 'StoreFront_Branch' | undefined;
  id: string;
  name: string;
  website?: string | null | undefined;
  streetAddress: string;
  addressLocality: string;
  postalCode: string;
  contactPhone?: string | null;
  contactEmail?: string | null;
  tableReservationSetting: {
    seatLimit: number;
    phone: string;
    status: boolean;
    flexDuration: number[];
    flexEnabled: boolean;
  };
  company: {
    logo?: string | null | undefined;
    settings: {
      customerDefaultLanguage: string;
      timezone: string;
    };
  };
}

const TableReservationCreateForm = (props: { branchID: string; companyID: string; selectedBranch: SelectedBranch }) => {
  const { selectedBranch, branchID, companyID } = props;
  const { data } = useCompany(companyID);
  localStorage.setItem('language', data?.storefront_company.settings.customerDefaultLanguage ?? 'de');
  const [currentLanguage, setCurrentLanguage] = useLocalStorage('language', 'en');
  const navigate = useNavigate();
  const [emptyError, setEmptyError] = useState<Record<string, boolean>>({});
  const {
    data: form,
    handle,
    manualHandle,
  } = useFormData<TableBookingCreateForm>({
    branchId: branchID,
    platformType: PlatformType.System,
    seats: 1,
    guestName: '',
    message: '',
    startTime: '',
    phone: '',
    email: '',
  });
  const [create, { error, loading: mutating }] = useCreateTableBooking();

  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const [flexDuration, setFlexDuration] = useState<number | null>(null);

  useEffect(() => {
    document.title = `Reservation at ${selectedBranch.name}`;
    setCurrentLanguage(selectedBranch.company.settings.customerDefaultLanguage);
  }, [selectedBranch, setCurrentLanguage]);

  useEffect(() => {
    logEvent(analytics, 'table_reservation_start', {
      companyID: companyID,
      branchID: branchID,
    });
  }, [branchID, companyID]);

  const validate = () => {
    let flag = true;
    setEmptyError({});
    if (mutating) {
      flag = false;
    }
    if (form.branchId === '') {
      flag = false;
    }
    if (form.guestName === '') {
      setEmptyError((previousEmptyError) => ({
        ...previousEmptyError,
        guestName: true,
      }));
      flag = false;
    }
    if (form.email === '') {
      setEmptyError((previousEmptyError) => ({
        ...previousEmptyError,
        email: true,
      }));
      flag = false;
    }
    if (form.phone === '') {
      setEmptyError((previousEmptyError) => ({
        ...previousEmptyError,
        phone: true,
      }));
      flag = false;
    }
    if (isNaN(form.seats)) {
      flag = false;
    }
    if (form.startTime === '') {
      setEmptyError((previousEmptyError) => ({
        ...previousEmptyError,
        startTime: true,
      }));
      flag = false;
    }

    return flag;
  };

  const analyticBeginEdit = (fieldName: string) => {
    logEvent(analytics, 'begin_edit_' + fieldName, {
      companyID: companyID,
      branchID: branchID,
    });
  };

  const analyticEndEdit = (fieldName: string) => {
    logEvent(analytics, 'end_edit_' + fieldName, {
      companyID: companyID,
      branchID: branchID,
    });
  };

  console.log(form.startTime);

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }

    create({
      variables: {
        branchId: form.branchId,
        input: {
          customerLanguage: currentLanguage,
          platformType: form.platformType,
          seats: +form.seats,
          guestName: form.guestName,
          message: form.message,
          startTime: form.startTime,
          flexDuration: flexDuration === 0 ? null : flexDuration,
          phone: form.phone,
          email: form.email,
        },
      },
    })
      .then((value) => {
        logEvent(analytics, 'table_reservation_end', {
          companyID: companyID,
          branchID: branchID,
        });

        navigate(
          '/table-reservation/track?tableReservationId=' +
            (value.data?.storefront_createTableReservation.id ?? '') +
            '&branchId=' +
            'default'
        );
      })
      .catch((e) => {
        logEvent(analytics, 'table_reservation_failure_create', {
          companyID: companyID,
          branchID: branchID,
          ...validationError,
        });

        captureException(e);
      });
  };

  const handleSeats = (value: number) => {
    let result = value;
    if (value < 1) {
      result = 1;
    }
    if (value > selectedBranch.tableReservationSetting.seatLimit) {
      result = selectedBranch.tableReservationSetting.seatLimit;
    }
    manualHandle('seats', result);
  };

  const { t } = useTranslate();

  const guestForm = (
    <>
      {/* Guest name */}
      <div className="sm:col-span-4">
        <label htmlFor="guestName" className="block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.create-form.form-input.guest-name.label', 'Guest Name')}
        </label>
        <div className="mt-2">
          <input
            id="guestName"
            name="guestName"
            type="guestName"
            value={form.guestName}
            onChange={handle}
            onFocus={(e) => {
              analyticBeginEdit(e.target.name);
            }}
            onBlur={(e) => {
              analyticEndEdit(e.target.name);
            }}
            autoComplete="guestName"
            className="block w-full rounded-md border-0 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        {validationError.guestName && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {validationError.guestName}
          </p>
        )}
        {emptyError.guestName && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {t('reservation.create-form.form-input.empty-error.guest-name', 'Guest name is required')}
          </p>
        )}
      </div>
      {/* Email */}
      <div className="sm:col-span-4">
        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.create-form.form-input.email.label', 'Email')}
        </label>
        <div className="mt-2">
          <input
            id="email"
            name="email"
            type="email"
            value={form.email}
            onChange={handle}
            onFocus={(e) => {
              analyticBeginEdit(e.target.name);
            }}
            onBlur={(e) => {
              analyticEndEdit(e.target.name);
            }}
            autoComplete="email"
            className="block w-full rounded-md border-0 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        {validationError.email && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {validationError.email}
          </p>
        )}
        {emptyError.email && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {t('reservation.create-form.form-input.empty-error.email', 'Email is required')}
          </p>
        )}
      </div>

      {/* Phones */}
      <div className="sm:col-span-4">
        <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.create-form.form-input.phone.label', 'Phone')}
        </label>
        <div className="mt-2">
          <PhoneInput
            className="block w-full rounded-md border-0 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultCountry={'DE'}
            placeholder={t('reservation.create-form.form-input.phone.placeholder', 'Enter phone number')}
            value={form.phone}
            onChange={(value) => {
              manualHandle('phone', value?.toString() ?? '');
            }}
            onFocus={() => {
              analyticBeginEdit('phone');
            }}
            onBlur={() => {
              analyticEndEdit('phone');
            }}
          />
          {validationError.phone && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {validationError.phone}
            </p>
          )}
          {emptyError.phone && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {t('reservation.create-form.form-input.empty-error.phone', 'Phone is required')}
            </p>
          )}
        </div>
      </div>

      {/*  Seats */}
      <div className="sm:col-span-3">
        <label htmlFor="seats" className="block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.create-form.form-input.seats.label', 'Number of guests')}{' '}
          <span className="pl-1 font-normal italic text-gray-400">
            {'( '}
            {t(
              'reservation.create-form.form-input.seats.description',
              'Get contact to us if your group has more than '
            )}
            {selectedBranch.tableReservationSetting.seatLimit}
            {' )'}
          </span>
        </label>
        <div className="mt-2">
          <div className="flex">
            <p
              onClick={() => {
                handleSeats(form.seats - 1);
              }}
              className="min-w-10 cursor-pointer rounded-l-md border-0 p-2 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100"
            >
              -
            </p>
            <input
              className="block w-full max-w-32 border-0 p-2 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              type="number"
              id="seats"
              name="seats"
              value={form.seats}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onChange={() => {}}
              min="1"
              max={selectedBranch.tableReservationSetting.seatLimit}
              readOnly
            />
            <div
              onClick={() => {
                handleSeats(form.seats + 1);
              }}
              className="min-w-10 cursor-pointer rounded-r-md p-2 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100"
            >
              +
            </div>
          </div>

          {validationError.seats && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {validationError.seats}
            </p>
          )}
        </div>
      </div>

      {/* Message */}
      <div className="col-span-full">
        <label htmlFor="message" className="block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.create-form.form-input.message.label', 'Message')}
        </label>
        <div className="mt-2">
          <textarea
            id="message"
            name="message"
            rows={3}
            value={form.message}
            onChange={(e) => {
              manualHandle('message', e.target.value);
            }}
            onFocus={(e) => {
              analyticBeginEdit(e.target.name);
            }}
            onBlur={(e) => {
              analyticEndEdit(e.target.name);
            }}
            className="block w-full rounded-md border-0 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          {validationError.message && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {validationError.message}
            </p>
          )}
        </div>
      </div>

      {/*Flex Duration */}
      {selectedBranch.tableReservationSetting.flexEnabled && (
        <div className="col-span-full">
          <DurationInput
            items={selectedBranch.tableReservationSetting.flexDuration}
            value={flexDuration}
            onChange={(value) => {
              setFlexDuration(value);
              logEvent(analytics, 'table_reservation_flex_duration', {
                companyID: companyID,
                branchID: branchID,
                selected: value,
              });
            }}
          />
        </div>
      )}

      {/* Booking time */}
      <div className="flex w-full flex-col">
        <DateInput
          handleFunction={(_, value) => {
            // TODO: Improve
            manualHandle('startTime', value);
          }}
          attrName="startTime"
          branchID={form.branchId}
        />
        {validationError.guestName && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {validationError.startTime}
          </p>
        )}
        {emptyError.startTime && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {t('reservation.create-form.form-input.empty-error.date', 'You must select a date')}
          </p>
        )}
      </div>
    </>
  );

  return (
    <>
      <div className="mb-8">
        <HolidayTimeOutBanner branchId={branchID} timezone={selectedBranch.company.settings.timezone} />
      </div>
      <div className="mx-auto flex h-full max-w-xl items-center justify-center">
        <div className="border-1 flex flex-grow flex-col border px-8 py-8">
          {/* Restaurant Information*/}
          {
            <div className="mb-10 flex items-center justify-between border-b-4 border-gray-300 px-6 pb-8 pt-0">
              <div>
                {selectedBranch.company.logo && (
                  <img
                    className="w-35 h-20"
                    src={(import.meta.env.VITE_CDN_HOST as string) + '/' + selectedBranch.company.logo}
                    alt="Logo"
                  />
                )}
              </div>

              <div className="text-right text-sm italic text-gray-600">
                <p className="text-xl">{selectedBranch.name}</p>
                <p>{`${selectedBranch.streetAddress}, ${selectedBranch.postalCode} ${selectedBranch.addressLocality}`}</p>
                {selectedBranch.contactEmail && (
                  <div>
                    <a href={`mailto:${selectedBranch.contactEmail}`} className="pr-1 italic underline">
                      {selectedBranch.contactEmail}
                    </a>
                    <EnvelopeIcon className="inline-block h-4 w-4 pb-0.5" />
                  </div>
                )}
                {selectedBranch.tableReservationSetting.phone && (
                  <div>
                    <a href={`tel:${selectedBranch.tableReservationSetting.phone}`} className="pr-1 italic underline">
                      {selectedBranch.tableReservationSetting.phone}
                    </a>
                    <PhoneIcon className="inline-block h-4 w-4 pb-0.5" />
                  </div>
                )}
              </div>
            </div>
          }
          {/* Header */}
          <div className="flex items-center justify-between px-6">
            <div className="flex flex-col items-center">
              <p className="block text-3xl font-medium leading-6 text-gray-900">
                {t('reservation.header', 'Table reservation')}
              </p>
            </div>
            <div className="mt-auto flex">
              <LanguageSelector customerDefaultLanguage={data?.storefront_company.settings.customerDefaultLanguage} />
            </div>
          </div>
          {/* Content */}
          <div className="flex flex-col gap-4 border-b border-gray-900/10 px-6 py-8 pb-8">
            {form.branchId !== '' && guestForm}
          </div>
          {/* Footer */}
          <div className="mt-6 flex items-center justify-end gap-x-6">
            {selectedBranch.website && selectedBranch.website.length > 0 && (
              <button
                onClick={() => {
                  if (selectedBranch.website) {
                    window.location.href = selectedBranch.website;
                    logEvent(analytics, 'table_reservation_return_to_website', {
                      companyID: companyID,
                      branchID: branchID,
                    });
                  }
                }}
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {t('reservation.button.return-to-website', 'Return to website')}
              </button>
            )}
            <button
              type="submit"
              className={classNames(
                'rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-400',
                {}
              )}
              onClick={() => {
                handleSubmit();
              }}
            >
              <div className="flex items-center gap-1">
                {mutating && (
                  <div className="h-4 w-4">
                    <Spinner size={SpinnerSize.Small} />
                  </div>
                )}
                {t('reservation.button.reserve', 'Reserve')}
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableReservationCreateForm;

export function LanguageSelector(props: { customerDefaultLanguage?: string }) {
  const [selected, setSelected] = useState<{ id: number; language: string; languageCode: string; country: string }>(
    languages[0]
  );
  const { changeLanguage } = useTolgee(['language']);

  useEffect(() => {
    if (!props.customerDefaultLanguage) {
      return;
    }

    localStorage.setItem('language', props.customerDefaultLanguage);
    setSelected(languages.find((language) => language.languageCode == props.customerDefaultLanguage) ?? languages[0]);
    changeLanguage(props.customerDefaultLanguage).catch(captureException);
  }, [changeLanguage, props.customerDefaultLanguage]);

  const handleChange = (selectedLanguage: (typeof languages)[0]) => {
    setSelected(selectedLanguage);
    changeLanguageCallback(selectedLanguage.languageCode);
  };

  const changeLanguageCallback = (lng: string) => {
    changeLanguage(lng).catch(captureException);
    localStorage.setItem('language', lng);
  };

  return (
    <Listbox value={selected} onChange={handleChange}>
      <div className="relative mt-2">
        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
          <span className="block truncate">
            <img
              src={`https://flagcdn.com/16x12/${selected.country}.png`}
              width="20"
              height="16"
              alt={selected.country}
            />
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
          </span>
        </ListboxButton>

        <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {languages.map((lang) => (
            <ListboxOption
              key={lang.id}
              value={lang}
              className="relative m-1 cursor-default select-none py-2 pl-8 pr-4 text-gray-900 hover:bg-indigo-600 hover:text-white"
            >
              <span className="absolute inset-y-0 left-0 m-1 flex items-center pl-2">
                <img src={`https://flagcdn.com/16x12/${lang.country}.png`} width="20" height="16" alt={lang.country} />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}

const languages = [
  { id: 1, language: 'English', languageCode: 'en', country: 'us' },
  { id: 2, language: 'Germany', languageCode: 'de', country: 'de' },
  { id: 3, language: 'Vietnamese', languageCode: 'vi', country: 'vn' },
];
