import {
  BranchItemFragment,
  OnlineOrderBranchConfigurationItemFragment,
  OnlineOrderDeliveryMode,
  OnlineOrderItemFragment,
  OnlineOrderUserState,
} from '@app/graphql/types/graphql.ts';
import { CartSideBarPreviewMode } from '@app/page/online-order/fill-contact-info/utils/cart-side-bar-preview-mode-enum.ts';
import CartSideBarPreview from '@app/page/online-order/fill-contact-info/component/cart-side-bar-preview.tsx';
import { useState } from 'react';
import { useTranslate } from '@tolgee/react';
import { useCurrency } from '@app/components/price/use-currency.ts';
import { useProductPickerQuery } from '@app/page/online-order/product-picker/logic/use-product-picker-page-query.ts';
import { CardSelection, CardSelectionData } from '@app/components/card-selection.tsx';
import { formatCurrency } from '@app/components/price/currency-formatter.ts';
import {
  deliveryDiscountAdditionalText,
  pickupDiscountAdditionalText,
} from '@app/page/online-order/fill-contact-info/utils/pickup-discount-additional-text.ts';
import { useOnlineOrderFillPersonalData } from '@app/page/online-order/fill-contact-info/logic/use-online-order-fill-personal-contact.ts';
import { formatGraphQlError, validationErrors } from '@app/module/error/error.ts';
import { captureException } from '@sentry/react';
import useFormData from '@app/utils/use-form-data.ts';
import AutofillAddressInput from '@app/page/online-order/fill-contact-info/component/autofill-address-input.tsx';
import ContactInputCard from '@app/page/online-order/fill-contact-info/component/contact-input-card.tsx';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import SuperButton from '@app/components/super-button.tsx';

interface FormData {
  streetAddress: string;
  streetAddress2: string;
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
  addressCountry: string;

  guestName: string;
  email: string;
  phone: string;
  message: string;
}

export default function FillContactInfoPage(props: {
  branch: BranchItemFragment;
  order: OnlineOrderItemFragment;
  onlineOrderBranchConfiguration: OnlineOrderBranchConfigurationItemFragment;
}) {
  const { t } = useTranslate();
  const currency = useCurrency();
  const { data } = useProductPickerQuery(props.branch.id);

  const deliveryModes: CardSelectionData<OnlineOrderDeliveryMode>[] = [
    {
      id: OnlineOrderDeliveryMode.Delivery,
      value: OnlineOrderDeliveryMode.Delivery,
      title: t('online-order.fill-contact-page.form-input.delivery-mode.card-selection.delivery.title', 'Delivery'),
      description: t(
        'online-order.fill-contact-page.form-input.delivery-mode.card-selection.delivery.description',
        'We will deliver the order to your address'
      ),
      additionalText: [
        t(
          'online-order.fill-contact-page.form-input.delivery-mode.card-selection.delivery.addition-text',
          'Minimum order amount for delivery: '
        ) + ` ${formatCurrency(props.onlineOrderBranchConfiguration.minimumOrderAmountForDelivery, currency)}`,
        deliveryDiscountAdditionalText(
          props.onlineOrderBranchConfiguration.deliveryDiscountAmount,
          props.onlineOrderBranchConfiguration.deliveryDiscountType,
          currency
        ),
      ],
    },
    {
      id: OnlineOrderDeliveryMode.Pickup,
      value: OnlineOrderDeliveryMode.Pickup,
      title: t('online-order.fill-contact-page.form-input.delivery-mode.card-selection.pickup.title', 'Pickup'),
      description: t(
        'online-order.fill-contact-page.form-input.delivery-mode.card-selection.pickup.description',
        'You will pick up the order at the store'
      ),
      additionalText: [
        pickupDiscountAdditionalText(
          props.onlineOrderBranchConfiguration.pickupDiscountAmount,
          props.onlineOrderBranchConfiguration.pickupDiscountType,
          currency
        ),
      ],
    },
  ];

  // Params
  const availableDeliveryModes: CardSelectionData<OnlineOrderDeliveryMode>[] = [];

  if (data?.storefront_isOnlineOrderOpen.isDeliveryAvailable) {
    availableDeliveryModes.push(deliveryModes[0]);
  }

  if (data?.storefront_isOnlineOrderOpen.isPickupAvailable) {
    availableDeliveryModes.push(deliveryModes[1]);
  }

  let initialDeliveryMode = availableDeliveryModes.length > 0 ? availableDeliveryModes[0] : deliveryModes[1];
  if (props.order.deliveryMode) {
    initialDeliveryMode = deliveryModes.find((mode) => mode.value === props.order.deliveryMode) ?? deliveryModes[0];
  }

  // Hooks
  const [selectedDeliveryMode, setSelectedDeliveryMode] = useState(initialDeliveryMode);

  const [changeUserState, { loading, error }] = useOnlineOrderFillPersonalData();

  const {
    data: form,
    handle,
    manualHandle,
  } = useFormData<FormData>({
    streetAddress: props.order.streetAddress ?? '',
    streetAddress2: props.order.streetAddress2 ?? '',
    addressLocality: props.order.addressLocality ?? '',
    addressRegion: props.order.addressRegion ?? '',
    postalCode: props.order.postalCode ?? '',
    addressCountry: props.order.addressCountry ?? '',

    guestName: props.order.guestName ?? '',
    email: props.order.email ?? '',
    phone: props.order.phone ?? '',
    message: props.order.message ?? '',
  });

  const [emptyErrors, setEmptyErrors] = useState<Record<string, string>>({});

  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const validateEmpty = () => {
    let flag = true;
    const errors: Record<string, string> = {};
    if (form.guestName == '') {
      flag = false;
      errors.guestName = t('online-order.information.empty.guestName', 'Guest name should not be empty');
    }
    if (form.email == '') {
      flag = false;
      errors.email = t('online-order.information.empty.email', 'Email should not be empty');
    }
    if (form.phone == '') {
      flag = false;
      errors.phone = t('online-order.information.empty.phone', 'Phone name should not be empty');
    }
    if (form.streetAddress == '' && selectedDeliveryMode.value === OnlineOrderDeliveryMode.Delivery) {
      flag = false;
      errors.streetAddress = t('online-order.information.empty.streetAddress', 'Street address should not be empty');
    }
    if (form.addressLocality == '' && selectedDeliveryMode.value === OnlineOrderDeliveryMode.Delivery) {
      flag = false;
      errors.addressLocality = emptyErrors.streetAddress = t(
        'online-order.information.empty.addressLocality',
        'City should not be empty'
      );
    }
    if (form.postalCode == '' && selectedDeliveryMode.value === OnlineOrderDeliveryMode.Delivery) {
      flag = false;
      errors.postalCode = t('online-order.information.empty.postalCode', 'Postal code should not be empty');
    }

    setEmptyErrors(errors);
    return flag;
  };

  const saveAddressAndChangeState = (nextUserState: OnlineOrderUserState) => {
    if (!validateEmpty()) {
      return;
    }
    changeUserState({
      variables: {
        onlineOrderID: props.order.id,
        input: {
          deliveryMode: selectedDeliveryMode.value,

          streetAddress: form.streetAddress,
          streetAddress2: form.streetAddress2,
          addressLocality: form.addressLocality,
          addressRegion: form.addressRegion,
          postalCode: form.postalCode,
          addressCountry: form.addressCountry,

          guestName: form.guestName,
          email: form.email,
          phone: form.phone,
          message: form.message,

          nextUserState: nextUserState,
        },
      },
    }).catch(captureException);
  };

  const backToEditCartStateHandler = () => {
    changeUserState({
      variables: {
        onlineOrderID: props.order.id,
        input: {
          deliveryMode: selectedDeliveryMode.value,

          streetAddress: props.order.streetAddress ?? '',
          streetAddress2: props.order.streetAddress2 ?? '',
          addressLocality: props.order.addressLocality ?? '',
          addressRegion: props.order.addressRegion ?? '',
          postalCode: props.order.postalCode ?? '',
          addressCountry: props.order.addressCountry ?? '',

          guestName: props.order.guestName ?? '',
          email: props.order.email ?? '',
          phone: props.order.phone ?? '',
          message: props.order.message,

          nextUserState: OnlineOrderUserState.PickingProduct,
        },
      },
    }).catch(captureException);
  };

  const reviewDisable = () => {
    if (loading) {
      return true;
    }

    if (selectedDeliveryMode.value === OnlineOrderDeliveryMode.Delivery) {
      if (props.onlineOrderBranchConfiguration.minimumOrderAmountForDelivery > (props.order.basePrice ?? 0)) {
        return true;
      }
    }
    return false;
  };

  const reviewHandler = () => {
    saveAddressAndChangeState(OnlineOrderUserState.Review);
  };

  return (
    <div className="flex items-center justify-center bg-gray-100 sm:p-8">
      <div className="max-w-[1000px] grow space-y-4 sm:basis-2/3">
        <BackStateCard
          backToEditCartStateHandler={backToEditCartStateHandler}
          t={(key: string, defaultValue: string) => {
            return t(key, defaultValue);
          }}
        />
        <CartSideBarPreview
          order={props.order}
          onlineOrderBranchConfiguration={props.onlineOrderBranchConfiguration}
          deliveryMode={selectedDeliveryMode.value}
          mode={CartSideBarPreviewMode.OnlyProducts}
        />

        <CardSelection
          title={t('online-order.fill-contact-page.form-input.delivery-mode.label', 'Delivery mode')}
          data={availableDeliveryModes}
          value={selectedDeliveryMode}
          onChange={setSelectedDeliveryMode}
        />

        {selectedDeliveryMode.value === OnlineOrderDeliveryMode.Delivery && (
          <div>
            <AutofillAddressInput
              values={{ ...form }}
              onChange={handle}
              streetAddress={(value) => {
                manualHandle('streetAddress', value);
              }}
              error={{ ...validationError, ...emptyErrors }}
            />
          </div>
        )}

        <ContactInputCard
          values={{ ...form }}
          onChange={handle}
          manualHandle={(name: string, value: unknown) => {
            // @ts-expect-error accept for every type
            manualHandle(name as keyof FormData, value);
          }}
          errors={{ ...validationError, ...emptyErrors }}
        />

        <div className="px-1 sm:px-0">
          <SuperButton disabled={reviewDisable()} onClick={reviewHandler}>
            Next
          </SuperButton>
        </div>
      </div>
    </div>
  );
}

const BackStateCard = (props: {
  backToEditCartStateHandler: () => void;
  t: (key: string, defaultValue: string) => string;
}) => {
  return (
    <Card className="sticky top-0 z-10 shadow-md sm:relative sm:shadow-none">
      <CardHeader
        title={props.t('online-order.fill-contact-page.header', 'Back to edit cart')}
        handleBackButtonClick={props.backToEditCartStateHandler}
        withBackButton={true}
      />
    </Card>
  );
};
