import {
  BranchItemFragment,
  OnlineOrderDeliveryMode,
  OnlineOrderItemFragment,
  OnlineOrderState,
} from '@app/graphql/types/graphql.ts';
import CartSideBarPreview from '@app/page/online-order/fill-contact-info/component/cart-side-bar-preview.tsx';
import { CartSideBarPreviewMode } from '@app/page/online-order/fill-contact-info/utils/cart-side-bar-preview-mode-enum.ts';
import ContactAndAddressInfo from '@app/page/online-order/review/component/contact-and-address-info.tsx';
import moment from 'moment-timezone';
import BusinessCard from '@app/page/online-order/product-picker/component/business-card.tsx';
import { useTranslate } from '@tolgee/react';

export default function OrderDetailPage(props: { onlineOrder: OnlineOrderItemFragment; branch: BranchItemFragment }) {
  const { t } = useTranslate();
  const state = useOrderSteps(props.onlineOrder, t);

  return (
    <div className="flex items-center justify-center bg-gray-100 sm:p-8">
      <div className="max-w-[1000px] grow space-y-4 sm:basis-2/3">
        <BusinessCard branch={props.branch} />

        <CartSideBarPreview
          order={props.onlineOrder}
          editable={false}
          mode={CartSideBarPreviewMode.Full}
          headerChildren={
            <div
              className="border-1 flex items-center justify-center gap-2 rounded-3xl border px-3 py-1.5 font-semibold"
              style={{ color: state.colorCode, borderColor: state.colorCode }}
            >
              <span
                style={{ color: state.colorCode, borderColor: state.colorCode }}
                className="rounded-full border border-4"
              ></span>
              <span>{state.text}</span>
            </div>
          }
        />

        <ContactAndAddressInfo
          data={{
            address:
              props.onlineOrder.deliveryMode == OnlineOrderDeliveryMode.Delivery
                ? `${props.onlineOrder.addressLocality ?? ''}, ${props.onlineOrder.streetAddress ?? ''}, ${props.onlineOrder.postalCode ?? ''}, ${props.onlineOrder.addressCountry ?? ''}`
                : undefined,
            date: moment.tz(props.onlineOrder.createdAt, props.branch.company.settings.timezone).format('YYYY-MM-DD'),
            time: moment.tz(props.onlineOrder.createdAt, props.branch.company.settings.timezone).format('HH:mm'),
            guestName: props.onlineOrder.guestName ?? '',
            email: props.onlineOrder.email ?? '',
            phone: props.onlineOrder.phone ?? '',
            message: props.onlineOrder.message ?? '',
          }}
        />
      </div>
    </div>
  );
}

function useOrderSteps(
  onlineOrder: OnlineOrderItemFragment,
  t: (key: string, defaultValue: string) => string
): { text: string; colorCode: string } {
  let result: { text: string; colorCode: string };

  switch (onlineOrder.state) {
    case OnlineOrderState.Done:
      result = {
        text: t('online-order.state-page.done', 'Completed'),
        colorCode: '#50dc0e',
      };
      break;
    case OnlineOrderState.WaitingForConfirmation:
      result = {
        text: t('online-order.state-page.waiting', 'Waiting for confirmation'),
        colorCode: '#00abeb',
      };
      break;
    case OnlineOrderState.InProgress:
      result = {
        text: t('online-order.state-page.in-progress', 'In progressing'),
        colorCode: '#f1c40f',
      };
      break;
    case OnlineOrderState.Canceled:
      result = {
        text: t('online-order.state-page.cancel', 'Cancelled'),
        colorCode: '#f90b0b',
      };
      break;
  }
  return result;
}
