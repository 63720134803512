import { graphql } from '@app/graphql/types';

export const BranchFragment = graphql(`
  fragment BranchItem on StoreFront_Branch {
    id
    name
    website

    streetAddress
    addressLocality
    addressRegion
    postalCode
    addressCountry

    contactPhone
    contactEmail

    openingTimes {
      service
      closeTime
      openTime
      dayOfWeek
    }

    company {
      id
      logo
      name
      hubPage {
        banner
      }
      settings {
        currency
        customerDefaultLanguage
        timezone
      }
    }
  }
`);
