export default function ListLayout(props: {
  children: React.ReactNode;
  title: string;
  className?: string;
  id?: string;
  header?: React.ReactNode;
}) {
  return (
    <div className={props.className} id={props.id}>
      <div className="flex flex-col border-b bg-gray-50 px-4 py-2 text-lg font-semibold text-gray-500 sm:flex-row sm:items-center sm:justify-between">
        {props.title}
        {props.header}
      </div>
      <div>{props.children}</div>
    </div>
  );
}
