import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import CardContent from '@app/components/card/card-content.tsx';
import DescriptionItem from '@app/components/description/description-item.tsx';
import DualItem from '@app/components/form/dual-form-item.tsx';

export default function ContactAndAddressInfo(props: {
  data: {
    address: string | undefined;
    date: string;
    time: string;
    guestName: string;
    email: string;
    phone: string;
    message: string;
  };
}) {
  const { t } = useTranslate();

  return (
    <Card>
      <CardHeader title={t('online-order.review-page.contact-info.header', 'Contact Data & Delivery Address')} />

      <CardContent>
        <div className="space-y-10">
          {props.data.address && (
            <DescriptionItem
              label={t('online-order.review-page.review-side-bar.your-contact.address', 'Address')}
              value={props.data.address}
            />
          )}

          <DualItem className={'gap-0 space-y-10'}>
            <DescriptionItem
              label={t('online-order.review-page.review-side-bar.your-contact.date', 'Date')}
              value={props.data.date}
            />

            <DescriptionItem
              label={t('online-order.review-page.review-side-bar.your-contact.time', 'Time')}
              value={props.data.time}
            />
          </DualItem>

          <DescriptionItem
            label={t('online-order.review-page.review-side-bar.your-contact.name', 'Guest name')}
            value={props.data.guestName}
          />

          <DualItem className={'gap-0 space-y-10'}>
            <DescriptionItem
              label={t('online-order.review-page.review-side-bar.your-contact.email', 'Email')}
              value={props.data.email}
            />
            <DescriptionItem
              label={t('online-order.review-page.review-side-bar.your-contact.phone-number', 'Phone number')}
              value={props.data.phone}
            />
          </DualItem>

          {props.data.message !== '' && (
            <DescriptionItem
              label={t('online-order.review-page.review-side-bar.your-contact.message', 'Message')}
              value={props.data.message}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
}
