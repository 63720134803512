import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { useCreateOnlineOrder } from '@app/page/online-order/product-picker/logic/use-create-online-order.ts';
import { useProductPickerQuery } from '@app/page/online-order/product-picker/logic/use-product-picker-page-query.ts';
import { useProductPicker } from '@app/page/online-order/product-picker/logic/use-product-picker.ts';
import { getFragmentData } from '@app/graphql/types';
import { BranchFragment } from '@app/page/online-order/model/branch-fragment.ts';
import { OnlineOrderBranchConfigurationFragment } from '@app/page/online-order/model/online-order-branch-configuration.ts';
import { MenuFragment, MenuProductFragment } from '@app/page/online-order/model/menu-product-fragment.ts';
import { MenuSectionFragment } from '@app/page/online-order/model/menu-section-fragment.ts';
import { useEffect, useState } from 'react';
import Loading from '@app/components/loading.tsx';
import CriticalError from '@app/page/online-order/product-picker/component/critical-error.tsx';
import PageNotFound from '@app/page/online-order/product-picker/component/page-not-found.tsx';
import UnavailableOnlineOrder from '@app/page/online-order/product-picker/component/unavailable-online-order.tsx';
import { captureException } from '@sentry/react';
import { CurrencyContext } from '@app/components/price/use-currency.ts';
import ApplicationErrorView from '@app/module/error/application-error-view.tsx';
import ProductPickerList from '@app/page/online-order/product-picker/component/product-picker-list.tsx';
import { useTranslate } from '@tolgee/react';
import AlertBanner from '@app/page/online-order/product-picker/component/alert-banner.tsx';
import CartSideBar from '@app/page/online-order/product-picker/component/cart-side-bar.tsx';
import BusinessCard from '@app/page/online-order/product-picker/component/business-card.tsx';
import CartBar from '@app/page/online-order/product-picker/component/cart-bar.tsx';
import FunctionalBar from '@app/page/online-order/product-picker/component/functional-bar.tsx';
import { MenuProductItemFragment } from '@app/graphql/types/graphql.ts';

const ProductPickerPage = () => {
  const [params] = useSearchParams();
  const [, setCustomerLanguage] = useLocalStorage('language', 'de');
  const branchID = params.get('branchID');

  const navigate = useNavigate();
  const [createOnlineOrder, { loading: creatingOnlineOrder, error: creatingError }] = useCreateOnlineOrder();
  const { data, loading, error: loadingError } = useProductPickerQuery(branchID);
  const pickedProducts = useProductPicker((state) => state.products);
  const [products, setProducts] = useState<readonly MenuProductItemFragment[]>([]);

  const setupStore = useProductPicker((state) => state.setup);
  const clearCart = useProductPicker((state) => state.clear);

  const branch = getFragmentData(BranchFragment, data?.storefront_branch);
  const configuration = getFragmentData(
    OnlineOrderBranchConfigurationFragment,
    data?.storefront_onlineOrderBranchConfiguration
  );
  const menu = getFragmentData(MenuFragment, configuration?.menu);
  const sections = getFragmentData(MenuSectionFragment, menu?.sections) ?? [];

  const isOpenNow =
    data?.storefront_isOnlineOrderOpen.isDeliveryAvailable === true ||
    data?.storefront_isOnlineOrderOpen.isPickupAvailable === true;

  const error = loadingError ?? creatingError;

  useEffect(() => {
    if (!data) {
      return;
    }

    const products = getFragmentData(MenuProductFragment, menu?.menuProducts) ?? [];
    setProducts(products);
  }, [data, menu?.menuProducts]);

  useEffect(() => {
    if (branch) {
      setupStore(branch.id);
      setCustomerLanguage(branch.company.settings.customerDefaultLanguage);
    }
  }, [setCustomerLanguage, branch, setupStore]);

  // Check
  if (loading) return <Loading />;

  if (loadingError) {
    return <CriticalError />;
  }

  if (!branch) {
    return <PageNotFound />;
  }

  if (!configuration || !menu) {
    return <PageNotFound />;
  }

  if (!configuration.deliveryEnable && !configuration.pickupEnable) {
    return <UnavailableOnlineOrder website={branch.website ?? ''} />;
  }

  // Handler
  const createOrderHandler = () => {
    createOnlineOrder({
      variables: {
        branchID: branch.id,
        input: {
          customerLanguage: localStorage.getItem('language'),
          pickedProducts: pickedProducts.map((product) => ({
            productID: product.productID,
            quantity: product.quantity,
            configurations: product.configurations.map((config) => ({
              configurationID: config.configurationID,
              valueID: config.valueID,
            })),
          })),
        },
      },
    })
      .then((onlineOrder) => {
        if (!onlineOrder.data?.storefront_createOnlineOrder) {
          return;
        }

        clearCart();
        navigate(`/online-order/${onlineOrder.data.storefront_createOnlineOrder.id}`);
      })
      .catch((error) => {
        captureException(error);
      });
  };

  const searchProduct = (searchString: string) => {
    const products = getFragmentData(MenuProductFragment, menu.menuProducts);

    const newProducts = products.filter((item) => {
      if (item.title.toLowerCase().includes(searchString.toLowerCase().trim())) {
        return true;
      }
      return false;
    });

    setProducts(newProducts);
  };

  return (
    <CurrencyContext.Provider value={branch.company.settings.currency}>
      {(creatingOnlineOrder || !isOpenNow) && <OnlineOrderAlertBanner isOpenNow={isOpenNow} />}

      <div className="flex flex-col bg-gray-100 sm:p-8">
        <ApplicationErrorView className="my-4" error={error} />

        <div className="flex flex-col items-start justify-center gap-4 sm:flex-row">
          <div className="w-full space-y-4 xl:basis-1/2">
            <BusinessCard branch={branch} />

            <div>
              <div className="sticky top-0 z-10">
                <FunctionalBar searchProduct={searchProduct} sections={sections} products={products} />
              </div>
              <ProductPickerList timezone={branch.company.settings.timezone} sections={sections} products={products} />
            </div>
          </div>

          <div className="no-scrollbar top-4 hidden max-h-screen overflow-y-scroll xl:sticky xl:block xl:basis-[28%] 2xl:basis-1/5">
            <CartSideBar
              menu={menu}
              onCreate={createOrderHandler}
              disableOrderButton={creatingOnlineOrder || !isOpenNow}
            />
          </div>

          <CartBar menu={menu} onCreate={createOrderHandler} disableOrderButton={creatingOnlineOrder || !isOpenNow} />
        </div>
      </div>
    </CurrencyContext.Provider>
  );
};

function OnlineOrderAlertBanner({ isOpenNow }: { isOpenNow: boolean }) {
  const { t } = useTranslate();
  if (!isOpenNow) {
    return (
      <>
        <AlertBanner className="fixed z-50" description={t('online-order.picker-page.not-open-alert.description')} />
        <div className="fixed left-0 top-0 z-40 h-full w-full bg-gray-900 bg-opacity-50"></div>
      </>
    );
  } else {
    return <></>;
  }
}

export default ProductPickerPage;
