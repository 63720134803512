// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { AddressAutofill } from '@mapbox/search-js-react';
import { ChangeEvent } from 'react';
import { useTranslate } from '@tolgee/react';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import CardContent from '@app/components/card/card-content.tsx';
import TextInput from '@app/components/form/input/text-input.tsx';
import FormItem from '@app/components/form/form-item.tsx';
import FormLayout from '@app/components/form/form-layout.tsx';
import DualItem from '@app/components/form/dual-form-item.tsx';

const AutofillAddressInput = (props: {
  values: {
    streetAddress: string;
    streetAddress2: string;
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    // addressCountry: string;
  };
  streetAddress: (value: string) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: Record<string, string>;
}) => {
  const accessToken = import.meta.env.VITE_MAP_BOX as string;
  const { t } = useTranslate();
  // TODO: pass country and language from backend
  return (
    <AddressAutofill
      accessToken={accessToken}
      options={{
        country: 'de',
        language: 'de',
      }}
    >
      <Card className="flex flex-col space-y-4">
        <CardHeader title={t('online-order.fill-contact-page.form-input.delivery.address-title', 'Delivery address')} />
        <CardContent>
          <FormLayout>
            <FormItem title={t('online-order.fill-contact-page.form-input.delivery.address-1.label', 'Address line 1')}>
              <TextInput
                label="streetAddress"
                type="text"
                name="streetAddress"
                autoComplete="address-line1"
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder={t(
                  'online-order.fill-contact-page.form-input.delivery.address-1.placeholder',
                  'Address line 1'
                )}
                value={props.values.streetAddress}
                error={props.error?.streetAddress}
                onChange={(e) => {
                  props.streetAddress(e.target.value);
                }}
              />
            </FormItem>

            <FormItem title={t('online-order.fill-contact-page.form-input.delivery.address-2.label', 'Address line 2')}>
              <TextInput
                label="streetAddress2"
                type="text"
                name="streetAddress2"
                autoComplete="address-line2"
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder={t(
                  'online-order.fill-contact-page.form-input.delivery.address-2.placeholder',
                  'Address line 2'
                )}
                value={props.values.streetAddress2}
                error={props.error?.streetAddress2}
                onChange={props.onChange}
              />
            </FormItem>

            <DualItem>
              <FormItem title={t('online-order.fill-contact-page.form-input.delivery.city.label', 'City')}>
                <TextInput
                  type="text"
                  name="addressLocality"
                  autoComplete="address-level2"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder={t('online-order.fill-contact-page.form-input.delivery.city.placeholder', 'City')}
                  value={props.values.addressLocality}
                  error={props.error?.addressLocality}
                  onChange={props.onChange}
                />
              </FormItem>

              <FormItem
                title={t('online-order.fill-contact-page.form-input.delivery.postal-code.label', 'Postal code')}
              >
                <TextInput
                  type="text"
                  name="postalCode"
                  autoComplete="postal-code"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder={t('online-order.fill-contact-page.form-input.delivery.postal-code.placeholder', 'Zip')}
                  value={props.values.postalCode}
                  error={props.error?.postalCode}
                  onChange={props.onChange}
                />
              </FormItem>
            </DualItem>

            {/*<div>*/}
            {/*  <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900">*/}
            {/*    State*/}
            {/*  </label>*/}
            {/*  <input*/}
            {/*    type="text"*/}
            {/*    name="addressRegion"*/}
            {/*    autoComplete="address-level1"*/}
            {/*    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
            {/*    placeholder="State"*/}
            {/*    value={props.values.addressRegion}*/}
            {/*    onChange={props.onChange}*/}
            {/*  />*/}

            {/*  <ErrorLabel error={props.error?.addressRegion} />*/}
            {/*</div>*/}
          </FormLayout>
        </CardContent>
      </Card>
    </AddressAutofill>
  );
};

export default AutofillAddressInput;
