import {
  BranchItemFragment,
  OnlineOrderDeliveryFeeCalculationStatus,
  OnlineOrderDeliveryMode,
  OnlineOrderItemFragment,
  OnlineOrderUserState,
} from '@app/graphql/types/graphql.ts';
import { useOnlineOrderChangeUserState } from '@app/page/online-order/detail/logic/use-online-order-change-user-state.ts';
import { captureException } from '@sentry/react';
import { useTranslate } from '@tolgee/react';
import { CartSideBarPreviewMode } from '@app/page/online-order/fill-contact-info/utils/cart-side-bar-preview-mode-enum.ts';
import CartSideBarPreview from '@app/page/online-order/fill-contact-info/component/cart-side-bar-preview.tsx';
import ContactAndAddressInfo from '@app/page/online-order/review/component/contact-and-address-info.tsx';
import moment from 'moment-timezone';
import SuperButton from '@app/components/super-button.tsx';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';

export default function OnlineOrderReviewPage(props: {
  onlineOrder: OnlineOrderItemFragment;
  branch: BranchItemFragment;
}) {
  const { onlineOrder } = props;

  const [changeUserState, { loading }] = useOnlineOrderChangeUserState();

  const backToFillAddress = () => {
    changeUserState({
      variables: {
        onlineOrderID: props.onlineOrder.id,
        state: OnlineOrderUserState.FillContactInfo,
      },
    }).catch(captureException);
  };

  const submitHandler = () => {
    changeUserState({
      variables: {
        onlineOrderID: props.onlineOrder.id,
        state: OnlineOrderUserState.Done,
      },
    }).catch(captureException);
  };

  const validateForOrderButton = () => {
    if (onlineOrder.deliveryFeeCalculationStatus === OnlineOrderDeliveryFeeCalculationStatus.OutOfRange) {
      return true;
    }
    return false;
  };

  const { t } = useTranslate();

  return (
    <div className="flex items-center justify-center bg-gray-100 sm:p-8">
      <div className="max-w-[1000px] grow space-y-4 sm:basis-2/3">
        <BackStateCard
          backToFillAddress={backToFillAddress}
          t={(key: string, defaultValue: string) => {
            return t(key, defaultValue);
          }}
        />

        {/*<BusinessCard branch={props.branch} />*/}

        <CartSideBarPreview order={props.onlineOrder} editable={false} mode={CartSideBarPreviewMode.Full} />

        <ContactAndAddressInfo
          data={{
            address:
              props.onlineOrder.deliveryMode == OnlineOrderDeliveryMode.Delivery
                ? `${props.onlineOrder.addressLocality ?? ''}, ${props.onlineOrder.streetAddress ?? ''}, ${props.onlineOrder.postalCode ?? ''}, ${props.onlineOrder.addressCountry ?? ''}`
                : undefined,
            date: moment.tz(props.onlineOrder.createdAt, props.branch.company.settings.timezone).format('YYYY-MM-DD'),
            time: moment.tz(props.onlineOrder.createdAt, props.branch.company.settings.timezone).format('HH:mm'),
            guestName: props.onlineOrder.guestName ?? '',
            email: props.onlineOrder.email ?? '',
            phone: props.onlineOrder.phone ?? '',
            message: props.onlineOrder.message ?? '',
          }}
        />

        <div className="px-1 sm:px-0">
          <SuperButton disabled={loading || validateForOrderButton()} onClick={submitHandler}>
            {t('online-order.review-page.review-side-bar.button.order', 'Order')}
          </SuperButton>
        </div>
      </div>
    </div>
  );
}

const BackStateCard = (props: { backToFillAddress: () => void; t: (key: string, defaultValue: string) => string }) => {
  return (
    <Card className="sticky top-0 z-50 shadow-md sm:relative sm:shadow-none">
      <CardHeader
        title={props.t('online-order.review-page.back-to-edit-address', 'Back to edit address')}
        handleBackButtonClick={props.backToFillAddress}
        withBackButton={true}
      />
    </Card>
  );
};
