import { BranchItemFragment, DaysOfWeek, OpeningTimeServiceType } from '@app/graphql/types/graphql.ts';
import Card from '@app/components/card/card.tsx';
import CardContent from '@app/components/card/card-content.tsx';
import { LanguageSelector } from '@app/page/table-reservation/component/table-reservation-create-form.tsx';
import CDNLink from '@app/utils/cdn-link.ts';
import { useTranslate } from '@tolgee/react';

export default function BusinessCard(props: { branch: BranchItemFragment }) {
  const { t } = useTranslate();

  const today = todayIs();
  const pickup = {
    openTime: props.branch.openingTimes
      ?.filter((item) => item.dayOfWeek == today && item.service == OpeningTimeServiceType.OnlineOrderPickup)
      .sort((a, b) => {
        if (a.openTime < b.openTime) return -1;
        if (a.openTime > b.openTime) return 1;
        return 0;
      })[0]?.openTime,
    closeTime: props.branch.openingTimes
      ?.filter((item) => item.dayOfWeek == today && item.service == OpeningTimeServiceType.OnlineOrderPickup)
      .sort((a, b) => {
        if (a.closeTime > b.closeTime) return -1;
        if (a.closeTime < b.closeTime) return 1;
        return 0;
      })[0]?.closeTime,
  };

  const delivery = {
    openTime: props.branch.openingTimes
      ? props.branch.openingTimes
          .filter((item) => item.dayOfWeek == today && item.service == OpeningTimeServiceType.OnlineOrderDelivery)
          .sort((a, b) => {
            if (a.openTime < b.openTime) return -1;
            if (a.openTime > b.openTime) return 1;
            return 0;
          })[0]?.openTime
      : '',
    closeTime: props.branch.openingTimes
      ? props.branch.openingTimes
          .filter((item) => item.dayOfWeek == today && item.service == OpeningTimeServiceType.OnlineOrderDelivery)
          .sort((a, b) => {
            if (a.closeTime > b.closeTime) return -1;
            if (a.closeTime < b.closeTime) return 1;
            return 0;
          })[0]?.closeTime
      : '',
  };

  return (
    <>
      <Card className="z-10 shadow-md max-xl:rounded-none sm:relative sm:shadow-none">
        {props.branch.company.hubPage?.banner && (
          <div
            style={{
              backgroundImage: `url(${import.meta.env.VITE_CDN_HOST as string}/${props.branch.company.hubPage.banner ?? ''})`,
            }}
            className="hidden h-48 w-full bg-cover bg-center bg-no-repeat xl:block"
          ></div>
        )}
        <CardContent>
          <div className="flex">
            <div className="grow space-y-6">
              <div className="flex items-center gap-x-4">
                {props.branch.company.logo && (
                  <img
                    className="w-24 rounded-md"
                    src={CDNLink(props.branch.company.logo)}
                    alt={props.branch.company.name}
                  />
                )}
                <div>
                  <p className="pt-2 text-2xl font-medium">{props.branch.company.name}</p>
                  <p className="text-sm text-gray-400">
                    {props.branch.streetAddress} {props.branch.postalCode} {props.branch.addressLocality}
                  </p>
                  {props.branch.contactPhone && (
                    <a href={`tel:${props.branch.contactPhone}`} className="text-sm text-gray-400 underline">
                      {props.branch.contactPhone}
                    </a>
                  )}
                </div>
              </div>

              <div className="space-y-1 text-sm text-gray-500">
                {!pickup.openTime || !pickup.closeTime || (
                  <p>
                    {t('online-order.business-card.pickup', 'Today pickup')}: {pickup.openTime} - {pickup.closeTime}
                  </p>
                )}
                <p>
                  {!delivery.openTime || !delivery.closeTime || (
                    <p>
                      {t('online-order.business-card.delivery', 'Today delivery')}: {delivery.openTime} -{' '}
                      {delivery.closeTime}
                    </p>
                  )}
                </p>
              </div>
            </div>
            <div>
              <LanguageSelector customerDefaultLanguage={props.branch.company.settings.customerDefaultLanguage} />
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

function todayIs() {
  const daysOfWeeks: DaysOfWeek[] = [
    DaysOfWeek.Sunday,
    DaysOfWeek.Monday,
    DaysOfWeek.Tuesday,
    DaysOfWeek.Wednesday,
    DaysOfWeek.Thursday,
    DaysOfWeek.Friday,
    DaysOfWeek.Saturday,
  ];
  const date = new Date();
  return daysOfWeeks[date.getDay()];
}
