import { useQuery } from '@apollo/client';
import { graphql } from '../../graphql/types';

export const GET_MENU_PREVIEW_QUERY = graphql(`
  query GET_MENU_PREVIEW($companyID: ID!) {
    menuPreview(companyId: $companyID) {
      id
      type
      menuId
      url
      menu {
        id
        title
        companyId
        sections {
          id
          name
          description
        }
        menuProducts {
          id
          title
          description
          images
          ingredients
          section {
            id
            name
            description
          }
          configurations {
            id
            type
            values {
              price
              name
            }
          }
        }
      }
    }
  }
`);

export function useMenuPreview(id: string | null) {
  return useQuery(GET_MENU_PREVIEW_QUERY, { variables: { companyID: id ?? '' }, skip: !id });
}

const GET_COMPANY_QUERY = graphql(`
  query GetCompany($id: ID!) {
    storefront_company(id: $id) {
      id
      name
      logo
      settings {
        currency
        timezone
        customerDefaultLanguage
      }
    }
  }
`);

export function useCompany(id: string | null) {
  return useQuery(GET_COMPANY_QUERY, { variables: { id: id ?? '' }, skip: !id });
}
